import client from "../../../client";
import siteOptions from "@/siteconfigs/index";
import urlHelper from "@/utils/urlHelper.js";

const Api = {
  //获得列表
  getTransportPlanList: "/api/WarehouseService/TransitUserInBound/GetPagedList",

  //获得中转仓仓库列表
  getWarehouseList: "/api/WarehouseService/Warehouse/GetList",

  //获得打印预约信
  getPrintAppointmentLetterUrl: "/api/WarehouseService/TransitUserPrintInBound/GetTuckAppointmentLetter/tuckAppointmentLetter.pdf",
};

// post 看接口
export const getWarehouseList = ({ isActive = true, warehouseType = 8 }) => {
  return client.request({
    url: Api.getWarehouseList,
    data: {
      warehouseType,
      isActive,
    },
    method: "post",
  });
};


export const getPrintAppointmentLetterUrl = ( id ) => {
  let url = urlHelper.combineURLs(siteOptions.baseURL, Api.getPrintAppointmentLetterUrl);
  url = urlHelper.addQueryString(url, "planId", id);
  return url;
};


export const getTransportPlanList = ({
  planNo,
  planName,
  warehouseId,
  transportationMethod,
  boxNoOrCanScanedBoxNo,
  shipmentNameOrNo  ,
  containerNo,
  beginWareZoneAppointmentDate,
  endWareZoneAppointmentDate,
  transitStatus,
  beginCreationTime,
  endCreationTime,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getTransportPlanList,
    data: {
      planNo,
      planName,
      boxNoOrCanScanedBoxNo,
  shipmentNameOrNo  ,
      warehouseId,
      transportationMethod,
      containerNo,
      beginWareZoneAppointmentDate,
      endWareZoneAppointmentDate,
      transitStatus,
      beginCreationTime,
      endCreationTime,
      skipCount,
      maxResultCount,
      sorting,
    },
    method: "post",
  });
};
