<template>
  <a-tag v-if="type == scheduleTimeTypeEnum.takeGoods" color="green">{{ $t($enumLangkey('scheduleTimeType', scheduleTimeTypeEnum.takeGoods)) }}</a-tag>
  <a-tag v-else-if="type == scheduleTimeTypeEnum.forwarding" color="blue">{{ $t($enumLangkey('scheduleTimeType', scheduleTimeTypeEnum.forwarding)) }}</a-tag>
  <a-tag v-else-if="type == scheduleTimeTypeEnum.delivery" color="purple">{{ $t($enumLangkey('scheduleTimeType', scheduleTimeTypeEnum.delivery)) }}</a-tag>
</template>

<script>
import { defineComponent } from 'vue'
import { Tag } from 'ant-design-vue';
import { scheduleTimeType as scheduleTimeTypeEnum } from '@/enum/enum.json';

export default defineComponent({
  components: {
    ATag: Tag,
  },
  props: {
    type: [String, Number],
  },
  setup () {
    return {
      scheduleTimeTypeEnum,
    }
  }
})
</script>

<style scoped>

</style>