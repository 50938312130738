<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.out_pan") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row
        :gutter="[16, 16]"
        type="flex"
        justify="space-between"
        align="bottom"
      >
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                v-model:value="searchData.planNo"
                :placeholder="$t('warehouse.out_warehouse_plan_no')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
             <a-col>
              <a-input
                v-model:value="searchData.boxNoOrCanScanedBoxNo"
                :placeholder="$t('warehouse.box_id_or_scannable_box_number')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
              <a-col>
              <a-input
                v-model:value="searchData.shipmentNameOrNo  "
                :placeholder="$t('warehouse.shipment_name_or_shipment_number')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
            <a-col>
              <a-input
                v-model:value="searchData.planName"
                :placeholder="$t('warehouse.out_plan_name')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
            <a-col>
              <a-select
                style="width: 250px"
                v-model:value="searchData.transitDeliveryMethod"
                :placeholder="$t('warehouse.type_of_shipping')"
                allow-clear
              >
                <a-select-option
                  v-for="(value, key) in transitDeliveryMethodEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('transitDeliveryMethod', value))"
                >
                  {{ $t($enumLangkey("transitDeliveryMethod", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-form-item :label="$t('warehouse.creation_time')" class="mb-1">
                <a-range-picker
                  style="width: 250px"
                  @change="handleSelectedCreateDate"
                />
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item
                :label="$t('warehouse.reservation_time')"
                class="mb-1"
              >
                <a-input-group compact style="width: 350px">
                  <a-select
                    v-model:value="searchData.warehouseId"
                    :placeholder="$t('warehouse.out_warehouse')"
                    allow-clear
                    :showSearch="true"
                    :loading="dropDownData.warehouseListLoading"
                    optionFilterProp="search-key"
                    style="width: 40%;"
                    @change="handleChangeWarehouse"
                  >
                    <a-select-option
                      v-for="(value, key) in dropDownData.warehouseList"
                      :key="key"
                      :value="value?.id"
                      :title="`${value.warehouseNo}(${value.warehouseName})`"
                      :search-key="value.warehouseNo + value.warehouseName"
                      :timezone="value?.timezone"
                    >
                      {{ value.warehouseNo }}({{ value.warehouseName }})
                    </a-select-option>
                  </a-select>
                  <a-range-picker
                    v-model:value="searchData.appointmentTime"
                    :disabled="!searchData.warehouseId"
                    style="width: 60%;"
                    @change="handleSelectedDate"
                  />
                </a-input-group>
              </a-form-item>
            </a-col>
            <a-col>
              <a-select
                style="width: 250px"
                v-model:value="searchData.status"
                :placeholder="$t('warehouse.status')"
                allow-clear
              >
                <a-select-option
                  v-for="(value, key) in transitOutBoundUserStatusEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('transitOutBoundUserStatus', value))"
                >
                  {{ $t($enumLangkey("transitOutBoundUserStatus", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleSearch">{{
                $t("common.query")
              }}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <router-link :to="{ name: 'transit_out_plan_create' }">
            <a-button type="primary" ghost>{{
              $t("common.create")
            }}</a-button>
          </router-link>
        </a-col>
      </a-row>
      <div class="mt-3">
        <a-table
          :columns="columns"
          :data-source="list"
          :scroll="{ x: true, y: wrap.contentHeight - 180 }"
          :pagination="false"
          size="small"
          :loading="loading"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #planNoTitle>
            {{ $t("warehouse.out_plan_name") }}
            <div>
              {{ $t("warehouse.out_warehouse_plan_no") }}
            </div>
          </template>
          <template #planNo="{ record }">
            {{ record.planName }}
            <div>
              <CPlanNumberItem :no="record.planNo" />
            </div>
          </template>

          <template #scheduleDeliveryTimeTitle>
            <div>{{ $t("warehouse.date_of_reservation") }}</div>
            <div>
              <a-tag color="warning">{{ $t("warehouse.local_time") }}</a-tag>
            </div>
          </template>
          <template #scheduleDeliveryTime="{ record }">
            <div
              v-if="
                record.transitDeliveryMethod ==
                  transitDeliveryMethodEnum.seLogistics &&
                record.scheduleDeliveryType == scheduleDeliveryTypeEnum.date
              "
            >
              {{
                $filters.utcToSpecificTime(
                  record?.scheduleDeliveryTime,
                  record?.warehouseTimezone,
                  "yyyy-MM-DD"
                )
              }}
            </div>
            <div
              v-if="
                (record.transitDeliveryMethod ==
                  transitDeliveryMethodEnum.seLogistics &&
                  record.scheduleDeliveryType ==
                    scheduleDeliveryTypeEnum.dateAndTime) ||
                record.transitDeliveryMethod ==
                  transitDeliveryMethodEnum.takeTheir
              "
            >
              {{
                $filters.utcToSpecificTime(
                  record?.scheduleDeliveryTime,
                  record?.warehouseTimezone
                )
              }}
            </div>

            <div
              v-if="
                record.scheduleDeliveryType == scheduleDeliveryTypeEnum.anytime
              "
            >
              {{
                $t(
                  $enumLangkey(
                    "scheduleDeliveryType",
                    scheduleDeliveryTypeEnum.anytime
                  )
                )
              }}
            </div>

            <span>
              <ScheduleTimeTypeTag
                :type="record.scheduleTimeType"
              ></ScheduleTimeTypeTag>
            </span>
          </template>

          <template #transitDeliveryMethod="{ record }">
            {{
              $t(
                $enumLangkey(
                  "transitDeliveryMethod",
                  record.transitDeliveryMethod
                )
              )
            }}
          </template>

          <template #out_warehouse_type="{ record }">
            {{ $t($enumLangkey("destinationType", record.destinationType)) }}
          </template>

          <template #number_of_packages="{ record }">
            {{
              $filters.countProgressFormat(
                record.transitTotalInfo?.shipmentTotalCount?.actual,
                record.transitTotalInfo?.shipmentTotalCount?.plan
              )
            }}
          </template>

          <template #box_amount="{ record }">
            {{
              $filters.countProgressFormat(
                record.transitTotalInfo?.boxCount?.actual,
                record.transitTotalInfo?.boxCount?.plan
              )
            }}
          </template>

          <template #status="{ record }">
            {{ $t($enumLangkey("transitOutBoundUserStatus", record.status)) }}
          </template>

          <template #date>
            {{ $t("warehouse.create_date") }}
            <div>
              {{ $t("warehouse.update_date") }}
            </div>
          </template>
          <template #dateCustom="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>
              {{ $filters.utcToCurrentTime(record.lastModificationTime) }}
            </div>
          </template>

          <template #operateCustom="{ record }">
            <router-link
              :to="{
                name: 'transit_out_plan_detail',
                params: { id: record.id },
              }"
            >
              <a-button type="ghost">{{ $t("common.details") }}</a-button>
            </router-link>
          </template>
        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { defineComponent, reactive, toRefs, onMounted, onActivated } from "vue";
import {
  Row,
  Col,
  Tag,
  Spin,
  Table,
  Input,
  Tooltip,
  Select,
  Button,
  Form,
  DatePicker,
  Space,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CPlanNumberItem from "../../components/CPlanNumberItem.vue";
import ScheduleTimeTypeTag from "../../components/ScheduleTimeTypeTag.vue";
import { getWarehouseList } from "../../../api/modules/transit/inPlan/list";
import { getList } from "../../../api/modules/transit/outPlan/list";
import {
  transitDeliveryMethod as transitDeliveryMethodEnum,
  transitOutBoundUserStatus as transitOutBoundUserStatusEnum,
  scheduleDeliveryType as scheduleDeliveryTypeEnum,
} from "../../../enum/enum.json";
import { currentTimeToUtc } from "../../../utils/general";
import localPrint from "../../../utils/localPrint";

export default defineComponent({
  name: "transit_in_list",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ARangePicker: DatePicker.RangePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ASpace: Space,
    ATooltip: Tooltip,
    Content,
    CPager,
    CPlanNumberItem,
    ScheduleTimeTypeTag,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });

    const columns = [
      {
        width: 120,
        dataIndex: "warehouseNo",
        title: () => vueI18n.t("warehouse.out_warehouse_code"),
      },
      {
        width: 160,
        slots: {
          title: "planNoTitle",
          customRender: "planNo",
        },
      },
      {
        width: 150,
        slots: {
          title: "scheduleDeliveryTimeTitle",
          customRender: "scheduleDeliveryTime",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.type_of_shipping"),
        slots: {
          customRender: "transitDeliveryMethod",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.out_warehouse_type"),
        slots: {
          customRender: "out_warehouse_type",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.number_of_packages"),
        slots: {
          customRender: "number_of_packages",
        },
      },
      {
        width: 100,
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.status"),
        slots: {
          customRender: "status",
        },
      },
      {
        width: 150,
        slots: {
          title: "date",
          customRender: "dateCustom",
        },
      },
      {
        dataIndex: "operate",
        width: 100,
        title: () => vueI18n.t("common.operation"),
        fixed: "right",
        slots: {
          customRender: "operateCustom",
        },
      },
    ];

    const state = reactive({
      searchData: {
        planNo: null,
        planName: null,
        beginCreationTime: null,
        endCreationTime: null,
        beginScheduleDeliveryTime: null,
        endScheduleDeliveryTime: null,
        status: null,
        warehouseId: null,
         boxNoOrCanScanedBoxNo:null,
        shipmentNameOrNo  :null,
      },
      timezone: null,
      list: [],
      loading: false,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const dropDownData = reactive({
      warehouseList: [],
      warehouseListLoading: false,
    });

    const funcGetWarehouseList = () => {
      dropDownData.warehouseListLoading = true;
      getWarehouseList({ isActive: true })
        .then(({ result }) => {
          if (result) {
            dropDownData.warehouseList = result;
          } else {
            dropDownData.warehouseList = [];
          }
          dropDownData.warehouseListLoading = false;
        })
        .catch(() => {
          dropDownData.warehouseListLoading = false;
        });
    };

    const handleSelectedCreateDate = (e) => {
      let beginCreationTime = null;
      let endCreationTime = null;
      if (e.length === 2) {
        beginCreationTime = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endCreationTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchData.beginCreationTime = beginCreationTime;
      state.searchData.endCreationTime = endCreationTime;
    };

    const handleSelectedDate = (e) => {
      let beginScheduleDeliveryTime = null;
      let endScheduleDeliveryTime = null;
      if (e.length === 2) {
        beginScheduleDeliveryTime = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00"),
          state.timezone
        );
        endScheduleDeliveryTime = currentTimeToUtc(
          e[1]?.format("YYYY-MM-DD 00:00:00"),
          state.timezone
        );
      }
      state.searchData.beginScheduleDeliveryTime = beginScheduleDeliveryTime;
      state.searchData.endScheduleDeliveryTime = endScheduleDeliveryTime;
    };

    const handleChangeWarehouse = (value, expand) => {
      state.timezone = value ? expand?.timezone : null;
        if (!value) {
        state.searchData.appointmentTime = null;
        state.searchData.beginWareZoneAppointmentDate = null;
        state.searchData.endWareZoneAppointmentDate = null;
      }
    };

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcSearch();
    };

    const handleSearch = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      funcSearch();
    };

    const funcSearch = async () => {
      state.loading = true;
      getList({ ...state.searchData, ...state.pageData })
        .then((res) => {
          if (res.result) {
            let { items = [], totalCount = 0 } = res.result;
            state.list = items;
            state.pageData.totalCount = totalCount * 1;
          }
          state.loading = false;
        })
        .catch((e) => {
          state.loading = false;
        });
    };

    const handlePrint = (record) => {
      record.printLoading = true;
      getPrintData()
        .then(({ result }) => {
          if (result) {
            return localPrint(result);
          }
          record.printLoading = false;
        })
        .catch(() => {
          record.printLoading = false;
        });
    };

    onMounted(() => {
      funcGetWarehouseList();
      handleSearch();
    });

    onActivated(() => {
      handleSearch();
    });

    return {
      columns,
      ...toRefs(state),
      dropDownData,

      handleSelectedCreateDate,
      handleSelectedDate,
      handlePage,
      handleSearch,
      handlePrint,
      transitDeliveryMethodEnum,
      transitOutBoundUserStatusEnum,
      handleChangeWarehouse,
      scheduleDeliveryTypeEnum,
    };
  },
});
</script>

<style lang="less" scoped></style>
