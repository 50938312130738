import client from "../../../client";

const Api = {
  //获得列表
  getList: "/api/WarehouseService/TransitUserOutBound/GetPagedList",

  //获得中转仓仓库列表
  getWarehouseList: "/api/WarehouseService/Warehouse/GetList",

  //获得打印预约信
  getPrintData: "",
};

// post 看接口
export const getList = ({ 
	planNo,planName,transitDeliveryMethod,destinationType,boxNoOrCanScanedBoxNo,
  shipmentNameOrNo  ,
	beginCreationTime,endCreationTime,warehouseId, 
	beginScheduleDeliveryTime,endScheduleDeliveryTime,
	status,skipCount,maxResultCount
}) => {
  return client.request({
    url: Api.getList,
    data: {
			planNo,planName,transitDeliveryMethod,destinationType,boxNoOrCanScanedBoxNo,
      shipmentNameOrNo  ,
			beginCreationTime,endCreationTime,warehouseId, 
			beginScheduleDeliveryTime,endScheduleDeliveryTime,
			status,skipCount,maxResultCount
    },
    method: "post",
  });
};

export const getPrintData = () => {
  return client.request({
    url: Api.getPrintData,
    params: {},
    method: "get",
  });
};

